import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

import {
  Navigation,
  Home,
  Purchase,
  Wallet,
  Bounty,
  Gallery,
  About,
  Grid,
  Trinkets,
  Trinketswallet
} from "./components";

class App extends React.Component {

  render() {
    return (
      <div className="App">
        <Router>
          <Navigation />
          <Switch>
            <Route path="/" exact component={() => <Home />} />

            <Route path="/Purchase" exact component={() => <Purchase />} />
            <Route path="/Wallet" exact component={() => <Wallet />} />

            <Route path="/Gallery" exact component={() => <Gallery />} />
            <Route path="/About" exact component={() => <About />} />
            <Route path="/Trinkets" exact component={() => <Trinkets />} />
            <Route path="/Trinketswallet" exact component={() => <Trinketswallet />} />


          </Switch>
        </Router>
      </div>
    );
  }
}
export default App;
